import './Dashboard.scss';
import React, { useEffect, useState } from 'react';
import Container from '../../components/utils/Container/Container';
import Row from '../../components/utils/Row/Row';
import Column from '../../components/utils/Column/Column';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { useActions, useSelector } from '../../redux/hooks';
import { SelectEntry } from '../../components/MultiSelect/types';
import Box from '../../components/Box/Box';
import DatePicker from '../../components/DatePicker/DatePicker';
import Button, { Color, Icon } from '../../components/Button/Button';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import PerformanceCard from './PerformanceCard';
import Graph from './Graph';

const Dashboard: React.FC = () => {
  const {
    global: { merchantSelectList, dashboardInfo },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const [merchantCommaList, setMerchantCommaList] = useState('');
  const [tarih_araligi, setDateRange] = useState('');

  const [openCardIndex, setOpenCardIndex] = useState<number>();

  const [isBoxOpen, setIsBoxOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const { getDashboardInfoThunk } = useActions();

  const toggleBox = () => {
    setIsBoxOpen((prevState) => !prevState);
  };

  const fetchDashboardInfo = async () => {
    setLoading(true);
    await getDashboardInfoThunk({
      tarih_araligi: tarih_araligi ? tarih_araligi.split(' - ').join(',') : '',
      merchants: merchantCommaList,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (merchantSelectList.length > 0) setSelectedMerchants(merchantSelectList);
  }, [merchantSelectList]);

  useEffect(() => {
    setMerchantCommaList(selectedMerchants.map((e) => e.id).join(','));
  }, [selectedMerchants]);

  useEffect(() => {
    fetchDashboardInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerDashboard(event: { code: string; }) {
        if (event.code === 'Enter') {
        setItWorks(!itWorks);
        fetchDashboardInfo();
        }
    }
    fetchDashboardInfo();

    document.addEventListener('keypress', listenerDashboard);
    return () => {
    document.removeEventListener('keypress', listenerDashboard);
    }
  }, [!itWorks]);


  return (
    <Container>
      <Row width="90%">
        <Box style={{ padding: '30px', height: 'auto' }}>
          <Column width="30%">
            <LabeledContainer label="Tarih Aralığı">
              <DatePicker
                value={tarih_araligi}
                onChange={(startDate, endDate) => {
                  if (startDate && endDate)
                    setDateRange(startDate + ' - ' + endDate);
                  else setDateRange('');
                }}
              />
            </LabeledContainer>
          </Column>
          <Column width="50%">
            <MultiSelect
              value={selectedMerchants}
              height="90px"
              onChange={(selectedElements) => {
                setSelectedMerchants(selectedElements);
              }}
              list={merchantSelectList}
            />
          </Column>
          <Column width="20%">
            <Row margin="0" height="80px">
              <Button
                onClick={() => {
                  setItWorks(!itWorks);
                  fetchDashboardInfo();
                }}
                color={Color.orange}
                icon={Icon.search}
                loading={loading}
              >
                Göster
              </Button>
            </Row>
          </Column>
        </Box>
      </Row>

      {role_yonetim_listesi['Dashboard istatistikler'] === '1' && (
        <Row width="90%">
          <Box
            boxTitle={
              <div>
                <h3>İstatistik</h3>
              </div>
            }
            style={{
              paddingTop: '50px',
              height: isBoxOpen ? '240px' : '150px',
              overflow: 'hidden',
            }}
          >
            <Row
              onClick={() => toggleBox()}
              style={{ position: 'absolute', top: '50px' }}
              width="100%"
            >
              <Column className="summary-column" width="25%">
                <div>Toplam Yatırım</div>
                <Row className="toplam yatirim">
                  {dashboardInfo.context.toplam_yatirim.Toplam} TL
                </Row>
                <div className={`papara${!isBoxOpen ? ' hidden' : ''}`}>
                  - Papara: {dashboardInfo.context.toplam_yatirim.Papara} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payfix: {dashboardInfo.context.toplam_yatirim.Payfix} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payco: {dashboardInfo.context.toplam_yatirim.Payco} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Havale: {dashboardInfo.context.toplam_yatirim.Havale} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Kripto: {dashboardInfo.context.toplam_yatirim.Kripto} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - MFT: {dashboardInfo.context.toplam_yatirim.MFT} TL
                </div>
              </Column>
              <Column className="summary-column" width="25%">
                <div>Toplam Çekim</div>
                <Row className="toplam cekim">
                  {dashboardInfo.context.toplam_cekim.Toplam} TL
                </Row>
                <div className={`papara${!isBoxOpen ? ' hidden' : ''}`}>
                  - Papara: {dashboardInfo.context.toplam_cekim.Papara} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payfix: {dashboardInfo.context.toplam_cekim.Payfix} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payco: {dashboardInfo.context.toplam_cekim.Payco} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Havale: {dashboardInfo.context.toplam_cekim.Havale} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Kripto: {dashboardInfo.context.toplam_cekim.Kripto} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - MFT: {dashboardInfo.context.toplam_cekim.MFT} TL
                </div>
              </Column>
              <Column className="summary-column" width="25%">
                <div>Toplam Komisyon</div>
                <Row className="toplam komisyon">
                  {dashboardInfo.context.toplam_komisyon.Toplam} TL
                </Row>
                <div className={`papara${!isBoxOpen ? ' hidden' : ''}`}>
                  - Papara: {dashboardInfo.context.toplam_komisyon.Papara} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payfix: {dashboardInfo.context.toplam_komisyon.Payfix} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payco: {dashboardInfo.context.toplam_komisyon.Payco} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Havale: {dashboardInfo.context.toplam_komisyon.Havale} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Kripto: {dashboardInfo.context.toplam_komisyon.Kripto} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - MFT: {dashboardInfo.context.toplam_komisyon.MFT} TL
                </div>
              </Column>
              <Column
                className="summary-column"
                width="25%"
                title="[Kalan] = [Yatırım] - [Çekim] - [Komisyon]"
              >
                <div>Kalan</div>
                <Row className="toplam kalan">
                  {dashboardInfo.context.toplam_kalan.Toplam} TL
                </Row>
                <div className={`papara${!isBoxOpen ? ' hidden' : ''}`}>
                  {' '}
                  - Papara: {dashboardInfo.context.toplam_kalan.Papara} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payfix: {dashboardInfo.context.toplam_kalan.Payfix} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  - Payco: {dashboardInfo.context.toplam_kalan.Payco} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  {' '}
                  - Havale: {dashboardInfo.context.toplam_kalan.Havale} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  {' '}
                  - Kripto: {dashboardInfo.context.toplam_kalan.Kripto} TL
                </div>
                <div className={`havale${!isBoxOpen ? ' hidden' : ''}`}>
                  {' '}
                  - MFT: {dashboardInfo.context.toplam_kalan.MFT} TL
                </div>
              </Column>
            </Row>
          </Box>
        </Row>
      )}
      <Row padding="0" width="90%" style={{ justifyContent: 'flex-start' }}>
        {(role_yonetim_listesi['Dashboard toplam bakiye'] === '1' ||
          role_yonetim_listesi['Dashboard toplam Papara'] === '1' ||
          role_yonetim_listesi['Dashboard toplam Havale'] === '1' ||
          role_yonetim_listesi['Dashboard istatistikler'] === '1') && (
          <Column width="65%">
            {(role_yonetim_listesi['Dashboard toplam bakiye'] === '1' ||
              role_yonetim_listesi['Dashboard toplam Papara'] === '1' ||
              role_yonetim_listesi['Dashboard toplam Havale'] === '1') && (
              <Row height="150px">
                {role_yonetim_listesi['Dashboard toplam bakiye'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card bakiye" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.bakiye_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            Bakiye Toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-money" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
                {role_yonetim_listesi['Dashboard toplam Papara'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card papara" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.papara_kasa_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            Papara Kasa Toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-pied-piper" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
              </Row>
            )}
            {(role_yonetim_listesi['Dashboard toplam bakiye'] === '1' ||
              role_yonetim_listesi['Dashboard toplam PayFix'] === '1'  ||
              role_yonetim_listesi['Dashboard toplam Payco'] === '1'  ||
              role_yonetim_listesi['Dashboard toplam Kripto'] === '1') && (
              <Row height="150px">
                {role_yonetim_listesi['Dashboard toplam Kripto'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card bakiye" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.kripto_kasa_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            Kripto Kasa Toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-btc" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
                {role_yonetim_listesi['Dashboard toplam Payco'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card banka" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.payco_kasa_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            Payco kasa toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-bank" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
              </Row>
            )}
            {(role_yonetim_listesi['Dashboard toplam bakiye'] === '1' ||
              role_yonetim_listesi['Dashboard toplam Papara'] === '1' ||
              role_yonetim_listesi['Dashboard toplam Havale'] === '1') && (
              <Row height="150px">
                {role_yonetim_listesi['Dashboard toplam MFT'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card banka" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.mft_kasa_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            MFT kasa toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-bank" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
                {role_yonetim_listesi['Dashboard toplam PayFix'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card bakiye" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.payfix_kasa_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            Payfix Kasa Toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-btc" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
                {role_yonetim_listesi['Dashboard toplam Havale'] === '1' && (
                  <Column width="100%">
                    <Box float={true}>
                      <Row className="summary-card banka" width="100%">
                        <Column style={{ justifyContent: 'center' }}>
                          <Row className="amount" margin="0" padding="0">
                            {dashboardInfo.context.genel_kasa_toplami} TL
                          </Row>
                          <Row className="text" margin="0" padding="0">
                            Genel Banka kasa toplamı
                          </Row>
                        </Column>
                        <Column>
                          <Row className="icon">
                            <i className="la la-bank" />
                          </Row>
                        </Column>
                      </Row>
                    </Box>
                  </Column>
                )}
              </Row>
            )}
            {role_yonetim_listesi['Dashboard istatistikler'] === '1' && (
              <Row>
                <Column width="100%">
                  <Box
                    float={true}
                    boxTitle={
                      <div>
                        <h4>İstatistik grafiği</h4>
                      </div>
                    }
                  >
                    <Row width="100%" height="100%">
                      <Graph />
                    </Row>
                  </Box>
                </Column>
              </Row>
            )}
          </Column>
        )}
        {role_yonetim_listesi['Dashborad Backuser Performans Analizi'] ===
          '1' && (
          <Column width="35%">
            <Row height="400px">
              <Box
                className="performance-box"
                float={true}
                boxTitle={
                  <div>
                    <h4>Peformans Analizi</h4>
                  </div>
                }
              >
                <Row width="100%">
                  <Column width="100%">
                    {dashboardInfo.context.userPerformList.map(
                      (userPerformance, index) => (
                        <PerformanceCard
                          key={index}
                          number={index + 1}
                          onClick={() => {
                            if (openCardIndex === index) {
                              setOpenCardIndex(undefined);
                            } else {
                              setOpenCardIndex(index);
                            }
                          }}
                          hidden={index !== openCardIndex}
                          data={userPerformance}
                        />
                      )
                    )}
                  </Column>
                </Row>
              </Box>
            </Row>
          </Column>
        )}
      </Row>
    </Container>
  );
};

export default Dashboard;
