import React, { useState, useEffect } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const Virmans: React.FC = () => {
  const { 
    merchantSelectList, bankRegisterSelectList, paparaRegisterSelectList, 
    cryptoRegisterSelectList, paycoRegisterSelectList, payfixRegisterSelectList, mftRegisterSelectList
 } = useSelector(
    (state) => state.global
  );

  
  const navigate = useNavigate();

  const { showMessage, setLoading } = useActions();
  const types = [
    {id: "Havale", text: "Havale"}, 
    {id: "Kripto", text: "Kripto"}, 
    {id: "MFT", text: "MFT"}, 
    {id: "Papara", text: "Papara"}, 
    {id: "Payco", text: "Payco"}, 
    {id: "Payfix", text: "Payfix"}
  ]
  
  const [miktar, setAmount] = useState('');
  const [commission, setCost] = useState('');
  const [fromMerchant, setSelectedMerchant] = useState<SelectEntry>();
  const [fromKasa, setSelectedRegister] = useState<SelectEntry>();
  const [toKasa, setSelectedRegister2] = useState<SelectEntry>();
  
  const [fromType, setFromType] = useState<SelectEntry>();
  const [toType, setToType] = useState<SelectEntry>();
  const [fromListType, setFromListType] = useState<SelectEntry[]>();
  const [toListType, setToListType] = useState<SelectEntry[]>();
  
  const submitDisabled = isMissing([
    fromMerchant?.id,
    fromKasa?.id,
    toKasa?.id,
    miktar,
    commission,
    fromType?.text,
    toType?.text,
  ]);

  useEffect(() => {
    if (fromType?.text == "Havale"){
        setFromListType(bankRegisterSelectList);
    } else if(fromType?.text == "Kripto"){
        setFromListType(cryptoRegisterSelectList)
    } else if(fromType?.text == "MFT"){
        setFromListType(mftRegisterSelectList)
    } else if(fromType?.text == "Papara"){
        setFromListType(paparaRegisterSelectList)
    } else if(fromType?.text == "Payco"){
        setFromListType(paycoRegisterSelectList)
    } else if(fromType?.text == "Payfix"){
        setFromListType(payfixRegisterSelectList)
    }
  }, [fromType]);


  useEffect(() => {
    if (toType?.text == "Havale"){
        setToListType(bankRegisterSelectList);
    } else if(toType?.text == "Kripto"){
        setToListType(cryptoRegisterSelectList)
    } else if(toType?.text == "MFT"){
        setToListType(mftRegisterSelectList)
    } else if(toType?.text == "Papara"){
        setToListType(paparaRegisterSelectList)
    } else if(toType?.text == "Payco"){
        setToListType(paycoRegisterSelectList)
    } else if(toType?.text == "Payfix"){
        setToListType(payfixRegisterSelectList)
    }
  }, [toType]);

  const transferVirmanRegister = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/virman',
        data: {
          miktar: parseFloat(miktar),
          commission: parseFloat(commission),
          fromKasa: fromKasa?.id,
          toKasa: toKasa?.id,
          merchantId: fromMerchant?.id,
          fromType: fromType?.text,
          toType: toType?.text
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/virman');
    }
  };

  return (
    <Row width="100%" className="bank-register-transfer">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Virman</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <span className="to">Merchant</span>
              <LabeledContainer label="Merchant">
                <Select
                  value={fromMerchant}
                  onChange={(entry) => setSelectedMerchant(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Ödeme Yapılan Tip">
                <Select
                  value={fromType}
                  onChange={(entry) => setFromType(entry)}
                  list={types}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Kasa">
                <Select
                  value={fromKasa}
                  onChange={(entry) => setSelectedRegister(entry)}
                  list={fromListType || []}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Ödeme Yapılacak Tip">
                <Select
                  value={toType}
                  onChange={(entry) => setToType(entry)}
                  list={types}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Kasa">
                <Select
                  value={toKasa}
                  onChange={(entry) => setSelectedRegister2(entry)}
                  list={toListType || []}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Miktar">
                <input
                  type="number"
                  value={miktar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Komisyon Oranı">
                <input
                  type="number"
                  value={commission}
                  onChange={(event) => setCost(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await transferVirmanRegister();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Transfer Et
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Virmans;
