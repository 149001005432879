import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import Modal from '../../components/Modal/Modal';
import Virmans from './Virmans';

const VirmanList: React.FC = () => {
  const {
    global: { virmanList, virmanListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);


  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [isIncreaseModalOpen, setIsIncreaseModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [merchant, setMerchants] = useState('');
  const [miktar, setMikar] = useState('');
  const [komisyon, setKomisyon] = useState('');
  const [gonderenTip, setGonderenTip] = useState(0);
  const [gonderenNumber, setGonderenNumber] = useState('');
  const [gonderilenTip, setGonderilenTip] = useState('');
  const [gonderilenNumber, setGonderilenNumber] = useState('');
  const [olusturulmaTarihi, setOlusturulmaTarihi] = useState('');
  
  const { getVirmanListThunk, showMessage, setLoading } = useActions();

  const fetchData = async () => {
    getVirmanListThunk({
      id,
      merchant,
      miktar,
      komisyon,
      gonderenTip: gonderenTip.toString(),
      gonderenNumber,
      gonderilenTip,
      gonderilenNumber,
      olusturulmaTarihi,
      page,
      pageCount: pagination,
      column,
      order
    });
    setIsIncreaseModalOpen(false);
  };


  useEffect(() => {
    fetchData();
  }, [page, order, column, pagination]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsIncreaseModalOpen(isOpen);
        }}
        open={isIncreaseModalOpen}
        width="60vw"
      >
        <Virmans />
      </Modal>
      
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Virman Listesi</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
          <Column width="80%">
              <Row height="40px" padding="0" margin="5px 0"></Row>
            </Column>
            <Column width="20%">
              <Row height="40px" padding="0" margin="5px 0">
                {role_yonetim_listesi['Virman Yetki'] === '1' && (
                  <Column width="100%">
                    <Button
                      onClick={() => {
                        setIsIncreaseModalOpen(true);
                      }}
                      color={Color.blue}
                      icon={Icon.sync}
                    >
                      Transfer
                    </Button>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(pageNumber, orderColumn, orderType, pagination) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={virmanList} // Pass the structured data to DataTable
              loading={virmanListLoading}
              page={page}
              pagination={pagination}
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default VirmanList;
