import menuItems from './menu-items';
import { Link, matchPath } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';


const MenuList: React.FC = () => {
  
  const {
    auth: {
      availableRoutes,
      currentRoute,
      currentUser: { role_yonetim_listesi },
    },
    global: {
      newTransferCreated,
      newTransferStatusChanged,
      newCryptoTransferCreated,
      newCryptoTransferStatusChanged,
      newCreditCardTransferCreated,
      newCreditCardTransferStatusChanged,
      newPaparaTransferCreated,
      newPaparaTransferStatusChanged,
      newPayfixTransferCreated,
      newPayfixTransferStatusChanged,
      newPaycoTransferCreated,
      newPaycoTransferStatusChanged,
      newMFTTransferCreated,
      newMFTTransferStatusChanged,
      newWithdrawBankTransferCreated,
      newWithdrawBankTransferStatusChanged,
      newWithdrawPaparaTransferCreated,
      newWithdrawPaparaTransferStatusChanged,
      newWithdrawMFTTransferCreated,
      newWithdrawMFTTransferStatusChanged,
      newWithdrawCryptoTransferCreated,
      newWithdrawCryptoTransferStatusChanged,
      newWithdrawCreditCardTransferCreated,
      newWithdrawCreditCardTransferStatusChanged,
      newWithdrawPayfixTransferCreated,
      newWithdrawPayfixTransferStatusChanged,
      newWithdrawPaycoTransferCreated,
      newWithdrawPaycoTransferStatusChanged
    },
  } = useSelector((state) => state);
  
  const [flashButton, setFlashButton] = useState(false);
  const [flashPaparaButton, setFlashPaparaButton] = useState(false);
  const [flashCryptoButton, setFlashCryptoButton] = useState(false);
  const [flashMFTButton, setFlashMFTButton] = useState(false);
  const [flashCreditCardButton, setFlashCreditCardButton] = useState(false);
  const [flashPayfixButton, setFlashPayfixButton] = useState(false);
  const [flashPaycoButton, setFlashPaycoButton] = useState(false);


  const [flashWithdrawBankButton, setFlashWithdrawBankButton] = useState(false);
  const [flashWithdrawPaparaButton, setFlashWithdrawPaparaButton] = useState(false);
  const [flashWithdrawCryptoButton, setFlashWithdrawCryptoButton] = useState(false);
  const [flashWithdrawMFTButton, setFlashWithdrawMFTButton] = useState(false);
  const [flashWithdrawCreditCardButton, setFlashWithdrawCreditCardButton] = useState(false);
  const [flashWithdrawPayfixButton, setFlashWithdrawPayfixButton] = useState(false);
  const [flashWithdrawPaycoButton, setFlashWithdrawPaycoButton] = useState(false);


  const { 
    newTransferList, newPayfixTransferList, newPaparaTransferList, 
    newCryptoTransferList, newMFTTransferList, newPaycoTransferList,
    newWithdrawBankTransferList, newWithdrawPaparaTransferList, 
    newWithdrawCryptoTransferList, newWithdrawMFTTransferList,
    newCreditCardTransferList, newWithdrawCreditCardTransferList,
    newWithdrawPayfixTransferList, newWithdrawPaycoTransferList
  } = useSelector((state) => state.global);

  useEffect(() => {
    if (
      (newTransferCreated || newTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashButton
    ) {
      setFlashButton(true);
    }
  }, [
    newTransferCreated,
    newTransferStatusChanged,
    role_yonetim_listesi,
    flashButton,
  ]);

  useEffect(() => {
    if (
      (newPayfixTransferCreated || newPayfixTransferStatusChanged) &&
      role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashPayfixButton
    ) {
      setFlashPayfixButton(true);
    }
  }, [
    newPayfixTransferCreated,
    newPayfixTransferStatusChanged,
    role_yonetim_listesi,
    flashPayfixButton,
  ]);

  useEffect(() => {
    if (
      (newPaycoTransferCreated || newPaycoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Payco Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashPaycoButton
    ) {
      setFlashPaycoButton(true);
    }
  }, [
    newPaycoTransferCreated,
    newPaycoTransferStatusChanged,
    role_yonetim_listesi,
    flashPaycoButton,
  ]);

  useEffect(() => {
    if (
      (newPaparaTransferCreated || newPaparaTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashPaparaButton
    ) {
      setFlashPaparaButton(true);
    }
  }, [
    newPaparaTransferCreated,
    newPaparaTransferStatusChanged,
    role_yonetim_listesi,
    flashPaparaButton,
  ]);

  useEffect(() => {
    if (
      (newCryptoTransferCreated || newCryptoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashCryptoButton
    ) {
      setFlashCryptoButton(true);
    }
  }, [
    newCryptoTransferCreated,
    newCryptoTransferStatusChanged,
    role_yonetim_listesi,
    flashCryptoButton,
  ]);

  useEffect(() => {
    if (
      (newCreditCardTransferCreated || newCreditCardTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kredi Kart Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashCreditCardButton
    ) {
      setFlashCreditCardButton(true);
    }
  }, [
    newCreditCardTransferCreated,
    newCreditCardTransferStatusChanged,
    role_yonetim_listesi,
    flashCreditCardButton,
  ]);

  useEffect(() => {
    if (
      (newMFTTransferCreated || newMFTTransferStatusChanged) &&
      role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashMFTButton
    ) {
      setFlashMFTButton(true);
    }
  }, [
    newMFTTransferCreated,
    newMFTTransferStatusChanged,
    role_yonetim_listesi,
    flashMFTButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawBankTransferCreated || newWithdrawBankTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawBankButton
    ) {
      setFlashWithdrawBankButton(true);
    }
  }, [
    newWithdrawBankTransferCreated,
    newWithdrawBankTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawBankButton,
  ]);


  useEffect(() => {
    if (
      (newWithdrawPaparaTransferCreated || newWithdrawPaparaTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashPaparaButton
    ) {
      setFlashWithdrawPaparaButton(true);
    }
  }, [
    newWithdrawPaparaTransferCreated,
    newWithdrawPaparaTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawPaparaButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawPayfixTransferCreated || newWithdrawPayfixTransferStatusChanged) &&
      role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawPayfixButton
    ) {
      setFlashWithdrawPayfixButton(true);
    }
  }, [
    newWithdrawPayfixTransferCreated,
    newWithdrawPayfixTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawPayfixButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawPaycoTransferCreated || newWithdrawPaycoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Payco Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawPaycoButton
    ) {
      setFlashWithdrawPaycoButton(true);
    }
  }, [
    newWithdrawPaycoTransferCreated,
    newWithdrawPaycoTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawPaycoButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawCryptoTransferCreated || newWithdrawCryptoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawCryptoButton
    ) {
      setFlashWithdrawCryptoButton(true);
    }
  }, [
    newWithdrawCryptoTransferCreated,
    newWithdrawCryptoTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawCryptoButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawCreditCardTransferCreated || newWithdrawCreditCardTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kredi Kart Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawCreditCardButton
    ) {
      setFlashWithdrawCreditCardButton(true);
    }
  }, [
    newWithdrawCreditCardTransferCreated,
    newWithdrawCreditCardTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawCreditCardButton,
  ]);

  useEffect(() => {
    if (
      (newWithdrawMFTTransferCreated || newWithdrawMFTTransferStatusChanged) &&
      role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1' &&
      !flashWithdrawMFTButton
    ) {
      setFlashWithdrawMFTButton(true);
    }
  }, [
    newWithdrawMFTTransferCreated,
    newWithdrawMFTTransferStatusChanged,
    role_yonetim_listesi,
    flashWithdrawMFTButton,
  ]);


  useAsyncTimeout(
    () => {
      setFlashPayfixButton(false);
    },
    2000,
    [flashPayfixButton]
  );

  useAsyncTimeout(
    () => {
      setFlashPaycoButton(false);
    },
    2000,
    [flashPaycoButton]
  );

  useAsyncTimeout(
    () => {
      setFlashButton(false);
    },
    2000,
    [flashButton]
  );

  useAsyncTimeout(
    () => {
      setFlashPaparaButton(false);
    },
    2000,
    [flashPaparaButton]
  );

  useAsyncTimeout(
    () => {
      setFlashCryptoButton(false);
    },
    2000,
    [flashCryptoButton]
  );

  useAsyncTimeout(
    () => {
      setFlashCreditCardButton(false);
    },
    2000,
    [flashCreditCardButton]
  );

  useAsyncTimeout(
    () => {
      setFlashMFTButton(false);
    },
    2000,
    [flashMFTButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawBankButton(false);
    },
    2000,
    [flashWithdrawBankButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawPayfixButton(false);
    },
    2000,
    [flashWithdrawPayfixButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawPaycoButton(false);
    },
    2000,
    [flashWithdrawPaycoButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawPaparaButton(false);
    },
    2000,
    [flashWithdrawPaparaButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawCryptoButton(false);
    },
    2000,
    [flashWithdrawCryptoButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawCreditCardButton(false);
    },
    2000,
    [flashWithdrawCreditCardButton]
  );

  useAsyncTimeout(
    () => {
      setFlashWithdrawMFTButton(false);
    },
    2000,
    [flashWithdrawMFTButton]
  );

  return useMemo(() => {
    return (
      <>
        {menuItems
          .filter(({ route }) => availableRoutes.includes(route))
          .map(({ route, icon, label }) => (
            <li
              className={
                (matchPath(route, currentRoute) ? 'active' : '') +
                ((flashButton && route === 'yenihavale') ||
                (flashPaparaButton && route === 'yenipapara') ||
                (flashCryptoButton && route === 'yenikripto') ||
                (flashPaycoButton && route === 'yenipayco') ||
                (flashPayfixButton && route === 'yenipayfix') ||
                (flashCreditCardButton && route === 'yenikredikart') ||
                (flashMFTButton && route === 'yenimft')
                  ? ' flash'
                  : '')
              }
              key={route}
            >
              <Link to={`/${route}`} className="button">
                <i className={`icon ${icon}`} />
                <span className="label">{label}</span>
                {
                  label == 'Yeni Havale Yatırımları' ? <span className="circle" id="yty">{newTransferList.length}</span>: 
                  (label == 'Yeni Papara Yatırımları' ? <span className="circle" id="ypy">{newPaparaTransferList.length}</span>: 
                    (
                      label == 'Yeni Kripto Yatırımları' ? <span className="circle" id="ypy">{newCryptoTransferList.length}</span>: 
                      (
                        label == 'Yeni Kredi Kart Yatırımları' ? <span className="circle" id="ypy">{newCreditCardTransferList.length}</span>:
                        (
                          label == 'Yeni MFT Yatırımları' ? <span className="circle" id="ypy">{newMFTTransferList.length}</span>: 
                          (
                            label == 'Yeni Payco Yatırımları' ? <span className="circle" id="ypy">{newPaycoTransferList.length}</span>: 
                            (
                              label == 'Havale Çekimler' ? <span className="circle" id="ypy">{newWithdrawBankTransferList.length}</span>: 
                              (
                                label == 'Papara Çekimler' ? <span className="circle" id="ypy">{newWithdrawPaparaTransferList.length}</span>: 
                                (
                                  label == 'Kripto Çekimler' ? <span className="circle" id="ypy">{newWithdrawCryptoTransferList.length}</span>: 
                                  (
                                    label == 'MFT Çekimler' ? <span className="circle" id="ypy">{newWithdrawMFTTransferList.length}</span> : 
                                    (
                                      label == 'Kredi Kart Çekimler' ? <span className="circle" id="ypy">{newWithdrawCreditCardTransferList.length}</span>: 
                                      (
                                        label == 'Payco Çekimler' ? <span className="circle" id="ypy">{newWithdrawPaycoTransferList.length}</span> : (
                                            label == 'PayFix Çekimler' ? <span className="circle" id="ypy">{newWithdrawPayfixTransferList.length}</span> : (
                                              label == 'Yeni Payfix Yatırımları' ? <span className="circle" id="ypy">{newPayfixTransferList.length}</span> : ""
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        ) 
                      ) 
                    )
                  )
                }
              </Link>
            </li>
          ))}
      </>
    );
  }, [
    availableRoutes, currentRoute, 
    flashButton, flashPaparaButton, 
    flashCryptoButton, flashMFTButton,
    flashPayfixButton, flashWithdrawPayfixButton,
    flashPaycoButton, flashWithdrawPaycoButton,
    flashWithdrawBankButton, flashWithdrawPaparaButton, 
    flashWithdrawCryptoButton, flashWithdrawMFTButton,
    newTransferList, newPaparaTransferList, newPayfixTransferList,
    newCryptoTransferList, newMFTTransferList, newPaycoTransferList,
    newCreditCardTransferList, newWithdrawCreditCardTransferList,
    newWithdrawBankTransferList, newWithdrawPaparaTransferList, 
    newWithdrawCryptoTransferList, newWithdrawMFTTransferList,
    newWithdrawPayfixTransferList, newWithdrawPaycoTransferList
  ]);
};

export default MenuList;
