import './app.scss';
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Main from '../Main/Main';
import LoginPage from '../../pages/LoginPage/LoginPage';
import Dashboard from '../../pages/Dashboard/Dashboard';
import NewTransfer from '../../pages/NewTransfer/NewTransfer';
import NewPaparaTransfer from '../../pages/NewPaparaTransfer/NewPaparaTransfer';
import NewMFTTransfer from '../../pages/NewMFTTransfer/NewMFTTransfer';
import Reports from '../../pages/Reports/Reports';
import { useActions, useSelector } from '../../redux/hooks';
import CreateManuelDeposit from '../../pages/CreateManuelDeposit/CreateManuelDeposit';
import CreateManuelWithdraw from '../../pages/CreateManuelWithdraw/CreateManuelWithdraw';
import Deposits from '../../pages/Deposits/Deposits';
import WithdrawsBank from '../../pages/WithdrawsBank/Withdraws';
import WithdrawsPapara from '../../pages/WithdrawsPapara/Withdraws';
import WithdrawsMFT from '../../pages/WithdrawsMFT/Withdraws';
import WithdrawsCrypto from '../../pages/WithdrawsCrypto/Withdraws';
import PaparaMails from '../../pages/PaparaMails/PaparaMails';
import PaparaRegisters from '../../pages/PaparaRegisters/PaparaRegisters';
import MFTRegisters from '../../pages/MFTRegisters/MFTRegisters';
import BankRegisters from '../../pages/BankRegisters/BankRegisters';
import Cookies from 'js-cookie';

import CreatePaparaRegister from '../../pages/PaparaRegisters/CreatePaparaRegister';
import PaparaRegisterTransfer from '../../pages/PaparaRegisters/PaparaRegisterTransfer';
import PaparaRegisterDetails from '../../pages/PaparaRegisters/PaparaRegisterDetails';
import EditPaparaRegister from '../../pages/PaparaRegisters/EditPaparaRegister';

import CreateMFTRegister from '../../pages/MFTRegisters/CreateMFTRegister';
import MFTRegisterTransfer from '../../pages/MFTRegisters/MFTRegisterTransfer';
import MFTRegisterDetails from '../../pages/MFTRegisters/MFTRegisterDetails';
import EditMFTRegister from '../../pages/MFTRegisters/EditMFTRegister';

import CreateBankRegister from '../../pages/BankRegisters/CreateBankRegister';
import BankRegisterTransfer from '../../pages/BankRegisters/BankRegisterTransfer';
import BankRegisterDetails from '../../pages/BankRegisters/BankRegisterDetails';
import EditBankRegister from '../../pages/BankRegisters/EditBankRegister';
import Merchants from '../../pages/Merchants/Merchants';
import Sites from '../../pages/Site/Sites';
import UserGroups from '../../pages/UserGroup/UserGroups';
import CreateMerchant from '../../pages/Merchants/CreateMerchant';
import Users from '../../pages/Users/Users';
import CreateUser from '../../pages/Users/CreateUser';
import Roles from '../../pages/Roles/Roles';
import EditRole from '../../pages/Roles/EditRole';
import Banks from '../../pages/Banks/Banks';
import EditMerchant from '../../pages/Merchants/EditMerchant';
import Frauds from '../../pages/Frauds/Frauds';
import BanksSet from '../../pages/BanksSet/BanksSet';
import EditUser from '../../pages/Users/EditUser';
import InfoMessage from '../InfoMessage/InfoMessage';
import CurrentPaparaList from '../../pages/CurrentPaparaList/CurrentPaparaList';
import Loader from '../Loader/Loader';
import { routes } from '../../routes/routes';
import RouteManager from '../Route/RouteManager';
import { useAsyncTimeout } from '../../utils/utils';
import SoundLoader from '../Sound/SoundLoader';
import NewTransferCheck from '../NewTransferCheck/NewTransferCheck';
import NewPaparaTransferCheck from '../NewPaparaTransferCheck/NewPaparaTransferCheck';
import NewCryptoTransferCheck from '../NewCryptoTransferCheck/NewCryptoTransferCheck';
import NewMFTTransferCheck from '../NewMFTTransferCheck/NewMFTTransferCheck';

import NewWithdrawBankTransferCheck from '../NewWithdrawBankTransferCheck/NewWithdrawBankTransferCheck';
import NewWithdrawPaparaTransferCheck from '../NewWithdrawPaparaTransferCheck/NewWithdrawPaparaTransferCheck';
import NewWithdrawMFTTransferCheck from '../NewWithdrawMFTTransferCheck/NewWithdrawMFTTransferCheck';
import NewWithdrawCryptoTransferCheck from '../NewWithdrawCryptoTransferCheck/NewWithdrawCryptoTransferCheck';
import NewWithdrawPayfixTransferCheck from '../NewWithdrawPayfixTransferCheck/NewWithdrawPayfixTransferCheck';
import NewWithdrawPaycoTransferCheck from '../NewWithdrawPaycoTransferCheck/NewWithdrawPaycoTransferCheck';

import User from '../../pages/User/User';
import { Helmet } from 'react-helmet';
import NewCryptoTransfer from '../../pages/NewCryptoTransfer/NewCryptoTransfer';
import CryptoRegisters from '../../pages/CryptoRegisters/CryptoRegisters';
import CreateCryptoRegister from '../../pages/CryptoRegisters/CreateCryptoRegister';
import CryptoRegisterTransfer from '../../pages/CryptoRegisters/CryptoRegisterTransfer';
import CryptoRegisterDetails from '../../pages/CryptoRegisters/CryptoRegisterDetails';
import EditCryptoRegister from '../../pages/CryptoRegisters/EditCryptoRegister';

import WithdrawsCreditCard from '../../pages/WithdrawsCreditCard/Withdraws';
import WithdrawsPayfix from '../../pages/WithdrawsPayfix/Withdraws';
import NewCreditCardTransferCheck from '../NewCreditCardTransferCheck/NewCreditCardTransferCheck';
import NewCreditCardTransfer from '../../pages/NewCreditCardTransfer/NewCreditCardTransfer';
import CreditCardRegisters from '../../pages/CreditCardRegisters/CreditCardRegisters';
import CreateCreditCardRegister from '../../pages/CreditCardRegisters/CreateCreditCardRegister';
import CreditCardRegisterTransfer from '../../pages/CreditCardRegisters/CreditCardRegisterTransfer';
import CreditCardRegisterDetails from '../../pages/CreditCardRegisters/CreditCardRegisterDetails';
import EditCreditCardRegister from '../../pages/CreditCardRegisters/EditCreditCardRegister';
import NewWithdrawCreditCardTransferCheck from '../NewWithdrawCreditCardTransferCheck/NewWithdrawCreditCardTransferCheck';

import Validate2faPage from "../../pages/LoginPage/validate2fa";
import CreatePayFixRegister from '../../pages/Payfix/CreatePayFixRegister';
import PayFixRegisters from '../../pages/Payfix/PayFixRegisters';
import PayFixRegisterTransfer from '../../pages/Payfix/PayFixRegisterTransfer';
import PayFixRegisterDetails from '../../pages/Payfix/PayFixRegisterDetails';
import EditPayFixRegister from '../../pages/Payfix/EditPayFixRegister';
import NewPayfixTransfer from '../../pages/NewPayfixTransfer/NewPayfixTransfer';
import NewPayfixTransferCheck from '../NewPayfixTransferCheck/NewPayfixTransferCheck';
import EditUserGroup from '../../pages/UserGroup/EditUserGroup';
import CreateUserGroup from '../../pages/UserGroup/CreateUserGroup';

import WithdrawsPayco from '../../pages/WithdrawsPayco/Withdraws';
import CreatePaycoRegister from '../../pages/Payco/CreatePaycoRegister';
import PaycoRegisters from '../../pages/Payco/PaycoRegisters';
import PaycoRegisterTransfer from '../../pages/Payco/PaycoRegisterTransfer';
import PaycoRegisterDetails from '../../pages/Payco/PaycoRegisterDetails';
import EditPaycoRegister from '../../pages/Payco/EditPaycoRegister';
import NewPaycoTransfer from '../../pages/NewPaycoTransfer/NewPaycoTransfer';
import NewPaycoTransferCheck from '../NewPaycoTransferCheck/NewPaycoTransferCheck';
import VirmanList from '../../pages/Virman/VirmanList';


function App() {

  const [isIncognito, setIsIncognito] = useState(false);
  /*
  detectIncognito().then((result) => {
    if (result.isPrivate) {
      setIsIncognito(true);
    }
  });*/

  useEffect(() => {
    setIsIncognito(true);
  }, []);


  const {
    setIsLoggedIn,
    setMessageStatus,
    toggleTheme,
    toggleMenu,
    getCurrentUserThunk,
    setLoading,
    resetGlobalStore,
  } = useActions();

  const {
    notification: { showMessage },
    menu: { darkThemeEnabled, isLocked },
    auth: {
      availableRoutes,
      isLoggedIn,
      routesLoaded,
      userLoaded,
      currentRoute,
      currentUser
    },
  } = useSelector((state) => state);

  const [loaded, setLoaded] = useState(false);
  // const [otpIsValid, setOtpIsValid] = useState(false);
  useEffect(() => {
    resetGlobalStore();
  }, [currentRoute]);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        setLoading(true);
        await getCurrentUserThunk();
        setLoading(false);
        console.log(currentUser);
      }
    })();
  }, [getCurrentUserThunk, isLoggedIn]);

  useEffect(() => {
    if (Cookies.get('jwt')) {
      setIsLoggedIn(true);
    }
    setLoaded(true);
    if (window.localStorage.getItem('dark-theme') === '1') {
      if (!darkThemeEnabled) toggleTheme();
    }
    if (window.localStorage.getItem('is-menu-locked') === '0') {
      if (isLocked) toggleMenu();
    }
  }, []);

  useEffect(() => {
    if (darkThemeEnabled) {
      document.body.classList.add('dark-theme');
      window.localStorage.setItem('dark-theme', '1');
    } else {
      document.body.classList.remove('dark-theme');
      window.localStorage.setItem('dark-theme', '0');
    }
  }, [darkThemeEnabled]);

  useEffect(() => {
    if (isLocked) {
      window.localStorage.setItem('is-menu-locked', '1');
    } else {
      window.localStorage.setItem('is-menu-locked', '0');
    }
  }, [isLocked]);

  useAsyncTimeout(
    () => {
      if (showMessage) {
        setMessageStatus(false);
      }
    },
    5000,
    [showMessage]
  );


  const otpEnabled = currentUser && currentUser.otp_enabled;
  const otpIsValid = currentUser && currentUser.otp_is_valid;


  if (!loaded) return null;
  return (
    <BrowserRouter>
      <>
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <SoundLoader />
        <NewTransferCheck />
        <NewPaparaTransferCheck />
        <NewPayfixTransferCheck />
        <NewPaycoTransferCheck />
        <NewMFTTransferCheck />
        <NewCryptoTransferCheck />
        <NewCreditCardTransferCheck />
        <NewWithdrawBankTransferCheck />
        <NewWithdrawPaparaTransferCheck />
        <NewWithdrawMFTTransferCheck />
        <NewWithdrawCryptoTransferCheck />
        <NewWithdrawPayfixTransferCheck />
        <NewWithdrawPaycoTransferCheck />
        <NewWithdrawCreditCardTransferCheck />
        <RouteManager />
        <InfoMessage />
        <Loader />
        <Routes>
          {!userLoaded && <Route path="/login" element={<LoginPage />} />}
          { otpEnabled && !otpIsValid ?
            ( <Route path='*' element={<Validate2faPage />} />) :
            (
              <>
                <Route path="/*" element={<Main />}>
                  {routesLoaded && (
                    <>
                      {availableRoutes.includes(routes.dashboard) && (
                        <Route path={routes.dashboard} element={<Dashboard />} />
                      )}
                      {availableRoutes.includes(routes.yenihavale) && (
                        <Route path={routes.yenihavale} element={<NewTransfer />} />
                      )}
                      {availableRoutes.includes(routes.yenipapara) && (
                        <Route
                          path={routes.yenipapara}
                          element={<NewPaparaTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.yenimft) && (
                        <Route
                          path={routes.yenimft}
                          element={<NewMFTTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.yenikripto) && (
                        <Route
                          path={routes.yenikripto}
                          element={<NewCryptoTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.yenipayfix) && (
                        <Route
                          path={routes.yenipayfix}
                          element={<NewPayfixTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.yenipayco) && (
                        <Route
                          path={routes.yenipayco}
                          element={<NewPaycoTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.yenikredikart) && (
                        <Route
                          path={routes.yenikredikart}
                          element={<NewCreditCardTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.raporlar) && (
                        <Route path={routes.raporlar} element={<Reports />} />
                      )}
                      {availableRoutes.includes(routes.yatirimlar) && (
                        <Route path={routes.yatirimlar} element={<Deposits />} />
                      )}
                      {availableRoutes.includes(routes.havaleCekimler) && (
                        <Route path={routes.havaleCekimler} element={<WithdrawsBank />} />
                      )}
                      {availableRoutes.includes(routes.paparaCekimler) && (
                        <Route path={routes.paparaCekimler} element={<WithdrawsPapara />} />
                      )}
                      {availableRoutes.includes(routes.mftCekimler) && (
                        <Route path={routes.mftCekimler} element={<WithdrawsMFT />} />
                      )}
                      {availableRoutes.includes(routes.kriptoCekimler) && (
                        <Route path={routes.kriptoCekimler} element={<WithdrawsCrypto />} />
                      )}
                      {availableRoutes.includes(routes.kredikartCekimler) && (
                        <Route path={routes.kredikartCekimler} element={<WithdrawsCreditCard />} />
                      )}
                      {availableRoutes.includes(routes.payfixCekimler) && (
                        <Route path={routes.payfixCekimler} element={<WithdrawsPayfix />} />
                      )}
                      {availableRoutes.includes(routes.paycoCekimler) && (
                        <Route path={routes.paycoCekimler} element={<WithdrawsPayco />} />
                      )}
                      {availableRoutes.includes(routes.guncelPaparalar) && (
                        <Route
                          path={routes.guncelPaparalar}
                          element={<CurrentPaparaList />}
                        />
                      )}
                      {availableRoutes.includes(routes.paparaKasalar) && (
                        <Route
                          path={routes.paparaKasalar}
                          element={<PaparaRegisters />}
                        />
                      )}
                      {availableRoutes.includes(routes.paparaKasaOlustur) && (
                        <Route
                          path={routes.paparaKasaOlustur}
                          element={<CreatePaparaRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.paparaKasaTransfer) && (
                        <Route
                          path={routes.paparaKasaTransfer}
                          element={<PaparaRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.paparaKasaDetay) && (
                        <Route
                          path={routes.paparaKasaDetay}
                          element={<PaparaRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.paparaKasaDuzenle) && (
                        <Route
                          path={routes.paparaKasaDuzenle}
                          element={<EditPaparaRegister />}
                        />
                      )}

                      {availableRoutes.includes(routes.mftKasalar) && (
                        <Route
                          path={routes.mftKasalar}
                          element={<MFTRegisters />}
                        />
                      )}
                      {availableRoutes.includes(routes.mftKasaOlustur) && (
                        <Route
                          path={routes.mftKasaOlustur}
                          element={<CreateMFTRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.mftKasaTransfer) && (
                        <Route
                          path={routes.mftKasaTransfer}
                          element={<MFTRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.mftKasaDetay) && (
                        <Route
                          path={routes.mftKasaDetay}
                          element={<MFTRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.mftKasaDuzenle) && (
                        <Route
                          path={routes.mftKasaDuzenle}
                          element={<EditMFTRegister />}
                        />
                      )}


                      {availableRoutes.includes(routes.payfixKasalar) && (
                        <Route
                          path={routes.payfixKasalar}
                          element={<PayFixRegisters />}
                        />
                      )}
                      {availableRoutes.includes(routes.payfixKasaOlustur) && (
                        <Route
                          path={routes.payfixKasaOlustur}
                          element={<CreatePayFixRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.payfixKasaTransfer) && (
                        <Route
                          path={routes.payfixKasaTransfer}
                          element={<PayFixRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.payfixKasaDetay) && (
                        <Route
                          path={routes.payfixKasaDetay}
                          element={<PayFixRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.payfixKasaDuzenle) && (
                        <Route
                          path={routes.payfixKasaDuzenle}
                          element={<EditPayFixRegister />}
                        />
                      )}


                      {availableRoutes.includes(routes.paycoKasalar) && (
                        <Route
                          path={routes.paycoKasalar}
                          element={<PaycoRegisters />}
                        />
                      )}
                      {availableRoutes.includes(routes.paycoKasaOlustur) && (
                        <Route
                          path={routes.paycoKasaOlustur}
                          element={<CreatePaycoRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.paycoKasaTransfer) && (
                        <Route
                          path={routes.paycoKasaTransfer}
                          element={<PaycoRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.paycoKasaDetay) && (
                        <Route
                          path={routes.paycoKasaDetay}
                          element={<PaycoRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.paycoKasaDuzenle) && (
                        <Route
                          path={routes.paycoKasaDuzenle}
                          element={<EditPayFixRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.kriptoKasalar) && (
                        <Route
                          path={routes.kriptoKasalar}
                          element={<CryptoRegisters />}
                        />
                      )}
                      {availableRoutes.includes(routes.kriptoKasaOlustur) && (
                        <Route
                          path={routes.kriptoKasaOlustur}
                          element={<CreateCryptoRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.kriptoKasaTransfer) && (
                        <Route
                          path={routes.kriptoKasaTransfer}
                          element={<CryptoRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.kriptoKasaDetay) && (
                        <Route
                          path={routes.kriptoKasaDetay}
                          element={<CryptoRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.kriptoKasaDuzenle) && (
                        <Route
                          path={routes.kriptoKasaDuzenle}
                          element={<EditCryptoRegister />}
                        />
                      )}

                      {availableRoutes.includes(routes.kredikartKasalar) && (
                        <Route
                          path={routes.kredikartKasalar}
                          element={<CreditCardRegisters />}
                        />
                      )}
                      {availableRoutes.includes(routes.kredikartKasaOlustur) && (
                        <Route
                          path={routes.kredikartKasaOlustur}
                          element={<CreateCreditCardRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.kredikartKasaTransfer) && (
                        <Route
                          path={routes.kredikartKasaTransfer}
                          element={<CreditCardRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.kredikartKasaDetay) && (
                        <Route
                          path={routes.kredikartKasaDetay}
                          element={<CreditCardRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.kredikartKasaDuzenle) && (
                        <Route
                          path={routes.kredikartKasaDuzenle}
                          element={<EditCreditCardRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.bankaSetListesi) && (
                        <Route path={routes.bankaSetListesi} element={<BanksSet />} />
                      )}
                      {availableRoutes.includes(routes.bankalar) && (
                        <Route path={routes.bankalar} element={<Banks />} />
                      )}
                      {availableRoutes.includes(routes.bankaKasalar) && (
                        <Route path={routes.bankaKasalar} element={<BankRegisters />} />
                      )}
                      {availableRoutes.includes(routes.bankaKasaOlustur) && (
                        <Route
                          path={routes.bankaKasaOlustur}
                          element={<CreateBankRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.bankaKasaTransfer) && (
                        <Route
                          path={routes.bankaKasaTransfer}
                          element={<BankRegisterTransfer />}
                        />
                      )}
                      {availableRoutes.includes(routes.bankaKasaDetay) && (
                        <Route
                          path={routes.bankaKasaDetay}
                          element={<BankRegisterDetails />}
                        />
                      )}
                      {availableRoutes.includes(routes.bankaKasaDuzenle) && (
                        <Route
                          path={routes.bankaKasaDuzenle}
                          element={<EditBankRegister />}
                        />
                      )}
                      {availableRoutes.includes(routes.paparaMailler) && (
                        <Route path={routes.paparaMailler} element={<PaparaMails />} />
                      )}
                      {availableRoutes.includes(routes.manuelYatirimOlustur) && (
                        <Route
                          path={routes.manuelYatirimOlustur}
                          element={<CreateManuelDeposit />}
                        />
                      )}
                      {availableRoutes.includes(routes.manuelCekimOlustur) && (
                        <Route
                          path={routes.manuelCekimOlustur}
                          element={<CreateManuelWithdraw />}
                        />
                      )}
                      {availableRoutes.includes(routes.virman) && (
                        <Route
                          path={routes.virman}
                          element={<VirmanList />}
                        />
                      )}
                      {availableRoutes.includes(routes.merchantlar) && (
                        <Route path={routes.merchantlar} element={<Merchants />} />
                      )}
                      {availableRoutes.includes(routes.sites) && (
                        <Route path={routes.sites} element={<Sites />} />
                      )}
                      {availableRoutes.includes(routes.userGroups) && (
                        <Route path={routes.userGroups} element={<UserGroups />} />
                      )}
                      {availableRoutes.includes(routes.userGroupOlustur) && (
                        <Route
                          path={routes.userGroupOlustur}
                          element={<CreateUserGroup />}
                        />
                      )}
                      {availableRoutes.includes(routes.userGroupDuzenle) && (
                        <Route
                          path={routes.userGroupDuzenle}
                          element={<EditUserGroup />}
                        />
                      )}
                      {availableRoutes.includes(routes.merchantOlustur) && (
                        <Route
                          path={routes.merchantOlustur}
                          element={<CreateMerchant />}
                        />
                      )}
                      {availableRoutes.includes(routes.merchantDuzenle) && (
                        <Route
                          path={routes.merchantDuzenle}
                          element={<EditMerchant />}
                        />
                      )}
                      {availableRoutes.includes(routes.kullanicilar) && (
                        <Route path={routes.kullanicilar} element={<Users />} />
                      )}
                      {availableRoutes.includes(routes.kullaniciOlustur) && (
                        <Route
                          path={routes.kullaniciOlustur}
                          element={<CreateUser />}
                        />
                      )}
                      {availableRoutes.includes(routes.kullaniciDuzenle) && (
                        <Route path={routes.kullaniciDuzenle} element={<EditUser />} />
                      )}
                      {availableRoutes.includes(routes.yetkiler) && (
                        <Route path={routes.yetkiler} element={<Roles />} />
                      )}
                      {availableRoutes.includes(routes.yetkiDuzenle) && (
                        <Route path={routes.yetkiDuzenle} element={<EditRole />} />
                      )}
                      {availableRoutes.includes(routes.fraudListesi) && (
                        <Route path={routes.fraudListesi} element={<Frauds />} />
                      )}
                      {availableRoutes.includes(routes.sifreDegistir) && (
                        <Route path={routes.sifreDegistir} element={<User />} />
                      )}

                      <Route path="*" element={<Navigate to={availableRoutes[0]} />} />

                    </>
                  )}
                </Route>

              </>
            )
          }
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
